import React from "react"

import { withFlexible } from "./withFlexible"
import { Wrapper } from "./FlexibleStyles"
import { Sections } from "../Sections/Sections"
import { BreadCrumbs } from "../BreadCrumbs/BreadCrumbs"

export const Flexible = withFlexible(({ title, page, hideTitle }) => (
  <>
    {hideTitle !== "hide" ? <BreadCrumbs paths={[{ title }]} /> : null}
    <Wrapper>
      <Sections page={page} />
    </Wrapper>
  </>
))
