import React from "react"
import { graphql } from "gatsby"

import { Flexible as Template } from "../components/Flexible/Flexible"

export const query = graphql`
  query($id: String!) {
    page: sanityPageFlexible(_id: { eq: $id }) {
      title
      slug {
        current
      }
      hide
      transparentHeader
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 6 })
    }
  }
`


export default ({ data, ...props }): JSX.Element => <Template {...props} {...data} />
