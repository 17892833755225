import React from "react"

export const withFlexible = Component => ({ name = "Flexible", page }) => {
  const { title, hide } = page


  console.log("page: ", page) 
  Component.displayName = name
  return <Component title={title} page={page} hideTitle={hide} />
}
